import { useAuth0 } from '@auth0/auth0-react';
import { parseISO } from 'date-fns';
import React, { useCallback, useMemo } from 'react';
import { Navigate, useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { routes } from 'src/OperationApp/routes';
import { isSelectFinancialAdvisorApplicable } from 'src/OperationApp/utils/auth';
import {
  checkSigningStatusFunction,
  DocusignUserType,
  getDocusignReturnToUrl,
  getDocusignUserType,
  getNextPendingSignee,
} from 'src/OperationApp/utils/docusign';

import {
  AlertAndLoading,
  Container,
  DocusignDAProCompleted,
  Grid,
  LegalDocumentStatus,
  useCoreConfig,
  useInterval,
} from '@sigfig/digital-wealth-core';

import { PartnerConfig } from '../../config';
import { Header } from '../../containers/Header';
import { isInIframe } from '../../utils';
import { AwaitingClientSign } from '../AwaitingClientSign';

import { useGetDocusignStatus } from './symphony';

export interface Props {
  dataQa?: string;
}

const DocusignCompletedWrapper: React.FC<Props> = ({ dataQa = 'docusign-completed-wrapper' }) => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const { managedProductId, partyId, bankAccountAssociationId } = useParams();
  const { contentOptions } = useCoreConfig<PartnerConfig>();
  const { user } = useAuth0();
  const namespace = 'https://fc.sigfig.com';
  const viewerPartyId = useMemo(() => {
    return user ? user[`${namespace}:inContextPartyId`] : '';
  }, [user]);
  const skipGetDocusignStatus = !(managedProductId && viewerPartyId !== '');
  const jwt = useMemo(() => {
    if (user) {
      return user[`${namespace}:frontEndJwt`];
    }
  }, [user]);

  const navigateToOpsDashboard = useCallback(() => {
    if (partyId) {
      navigate(routes.opsDashboard(partyId));
    }
  }, [partyId, navigate]);

  const {
    data: faDocusignStatusData,
    error: faDocusignStatusError,
    loading: faDocusignStatusLoading,
    refetch: refetchFaDocusignStatus,
  } = useGetDocusignStatus({
    variables: {
      managedProductId: managedProductId ?? '',
      partyId: viewerPartyId,
      baseUrl: 'https://sqa1-nyl-ops-dashboard.qa.sigfig.com/am/master',
      returnToUrl: getDocusignReturnToUrl(partyId ?? '', managedProductId, bankAccountAssociationId),
    },
    skip: skipGetDocusignStatus,
  });

  const userTypeParam = searchParams.get('userType');
  const userType = userTypeParam
    ? Object.values(DocusignUserType).find(v => v.toString().toLowerCase() === userTypeParam.toLowerCase()) ??
      DocusignUserType.FA
    : DocusignUserType.FA;
  const isUserTypeFA = userType === DocusignUserType.FA;
  const status = searchParams.get('status') as LegalDocumentStatus;
  const declinedBy = searchParams.get('declinedBy') as DocusignUserType;

  const managedProductLegalDocuments = faDocusignStatusData?.managedProduct?.legalDocuments
    ? [...faDocusignStatusData?.managedProduct?.legalDocuments]
    : undefined;

  const sortedManagedProductLegalDocuments = managedProductLegalDocuments?.sort((a, b) =>
    parseISO(a.created) > parseISO(b.created) ? -1 : 1,
  );
  const legalDocument = useMemo(() => {
    return bankAccountAssociationId
      ? sortedManagedProductLegalDocuments?.find(doc =>
          doc.associatedEntities?.bankAccountAssociationIds.includes(bankAccountAssociationId ?? ''),
        )
      : managedProductLegalDocuments?.[0];
  }, [managedProductLegalDocuments, bankAccountAssociationId]);

  const signeesListForPendingDocuments = useMemo(() => {
    return legalDocument?.status === LegalDocumentStatus.PENDING ? legalDocument.signees : [];
  }, [legalDocument]);

  const decliningSignee = useMemo(() => {
    return legalDocument?.signees.find(checkSigningStatusFunction(LegalDocumentStatus.DECLINED));
  }, [legalDocument]);

  useInterval(async () => {
    if (getNextPendingSignee(signeesListForPendingDocuments)) {
      await refetchFaDocusignStatus();
    }
  }, 20000);

  if (isInIframe() && window.top) {
    window.top.location.href = window.location.href;
    return null;
  }

  if (userType === DocusignUserType.FA && !viewerPartyId) {
    if (bankAccountAssociationId) {
      return (
        <Navigate
          to={routes.rceDocusignCompletedAuth(
            partyId ?? '',
            managedProductId ?? '',
            bankAccountAssociationId,
            status as LegalDocumentStatus,
            userType,
          )}
        />
      );
    }
  }

  const redirectToAccountSummary = () =>
    viewerPartyId !== '' ? navigate(routes.accountSummary(partyId ?? '')) : undefined;
  const showCtas = viewerPartyId !== '' && isUserTypeFA;

  const partyIdFA = faDocusignStatusData?.managedProduct?.createWorkflow?.initiatingAdvisorPartyId ?? '';
  const redirectOrRenderDocusignComponent = () => {
    if (userType === DocusignUserType.FA) {
      /** When the advisor selects the finish later option in the DocuSign package,
       * the advisor should be redirected to account summary */
      if (status === LegalDocumentStatus.PENDING) {
        redirectToAccountSummary();
        return;
      }
      if (
        legalDocument &&
        status === LegalDocumentStatus.SUCCEEDED &&
        getNextPendingSignee(signeesListForPendingDocuments)
      ) {
        /** When the advisor completes the signing in the DocuSign package,
         * the advisor should be redirected to docusign page and "Waiting for client signing" message should be shown */
        if (bankAccountAssociationId) {
          return (
            <AwaitingClientSign
              clientEmail={getNextPendingSignee(signeesListForPendingDocuments)?.email ?? ''}
              clientSignButtonOnClick={redirectToAccountSummary}
              contentOptions={contentOptions}
              isForBankAccountAssociation={!!bankAccountAssociationId}
            />
          );
        }
      }
    } else if (
      userTypeParam === DocusignUserType.Assistant &&
      isSelectFinancialAdvisorApplicable(jwt) &&
      status === LegalDocumentStatus.SUCCEEDED &&
      getNextPendingSignee(signeesListForPendingDocuments)
    ) {
      return (
        <AwaitingClientSign
          clientEmail={getNextPendingSignee(signeesListForPendingDocuments)?.email ?? ''}
          clientSignButtonOnClick={redirectToAccountSummary}
          contentOptions={contentOptions}
          isForBankAccountAssociation={!!bankAccountAssociationId}
        />
      );
    }
    return (
      <>
        <DocusignDAProCompleted
          contentOptions={contentOptions}
          declinedBy={declinedBy ?? getDocusignUserType(decliningSignee, partyIdFA)}
          isForBankAccountAssociation={!!bankAccountAssociationId}
          onFASignedPrimaryCtaCallback={redirectToAccountSummary}
          partyId={partyId ?? ''}
          showCtas={showCtas}
          signingStatus={decliningSignee ? LegalDocumentStatus.DECLINED : status}
          userType={userType}
        />
      </>
    );
  };

  return (
    <>
      <Header
        onBack={() => navigate(`/fa-dashboard/${partyId}`)}
        onHeaderLogoClick={navigateToOpsDashboard}
        showAdvisorButton={isUserTypeFA}
        showSaveAndExit={userTypeParam === 'AFA'}
      />
      <Container maxWidth="md">
        <Grid data-qa={dataQa} sx={{ py: 2 }}>
          {skipGetDocusignStatus ? (
            <DocusignDAProCompleted
              contentOptions={contentOptions}
              declinedBy={declinedBy ?? getDocusignUserType(decliningSignee, partyIdFA)}
              isForBankAccountAssociation={!!bankAccountAssociationId}
              onFASignedPrimaryCtaCallback={redirectToAccountSummary}
              partyId={partyId ?? ''}
              showCtas={showCtas}
              signingStatus={decliningSignee ? LegalDocumentStatus.DECLINED : status}
              userType={userType}
            />
          ) : (
            <>
              {!managedProductLegalDocuments || faDocusignStatusError || faDocusignStatusLoading ? (
                <AlertAndLoading
                  ariaLabel="Loading docusign data"
                  contentOptions={contentOptions}
                  error={faDocusignStatusError}
                  loading={faDocusignStatusLoading}
                />
              ) : (
                <>{redirectOrRenderDocusignComponent()}</>
              )}
            </>
          )}
        </Grid>
      </Container>
    </>
  );
};

export default DocusignCompletedWrapper;
