import { useAuth0 } from '@auth0/auth0-react';
import axios from 'axios';
import React, { lazy, useCallback, useEffect, useMemo, useState } from 'react';
import { Outlet, useLocation } from 'react-router-dom';

import {
  ApolloProvider,
  cacheTypePolicies as symphonyCacheTypePolicies,
  ErrorComponent,
  from,
  getApolloClient,
  getContentstackHttpLinkConfig,
  HttpLink,
  InMemoryCache,
  LoadingPage,
  onError,
  OpsDashboardHeaderV2,
  setContext,
  sfPosthog,
  useCoreConfig,
  usePostHog,
} from '@sigfig/digital-wealth-core';

import { KeepAlive } from '../../auth/KeepAlive';
import config, { PartnerConfig } from '../../config';
import { routes } from '../../routes';

const ProtectedRoute = lazy(() =>
  import('../../auth/ProtectedRoute').then(module => ({
    default: module.ProtectedRoute,
  })),
);

export const AuthedApp = ({ authenticationOptional }: { authenticationOptional?: boolean }) => {
  const posthog = usePostHog();
  const { contentOptions } = useCoreConfig<PartnerConfig>();
  const { isAuthenticated, isLoading, error, user, logout } = useAuth0();
  const [jwt, setJwt] = useState('');
  const [loggedInPartyId, setLoggedInPartyId] = useState('');

  sfPosthog.useCaptureSpaPageViews(useLocation());

  // TODO: Fix apollo client parameters
  // https://jira.sigfig.com/browse/DA2-696
  const getSymphonyHttpLinkConfig = useCallback(
    (uri: string) => {
      const symphonyAuthLink = setContext((_, { headers }) => {
        // get the authentication token from local storage if it exists

        // return the headers to the context so httpLink can read them
        return {
          headers: {
            ...headers,
            authorization: jwt ? `Bearer ${jwt}` : '',
          },
        };
      });

      const errorLink = onError(({ networkError }) => {
        if (
          (networkError as any)?.result?.status === 403 &&
          (networkError as any)?.result?.code === 'AUTHENTICATION_INVALID_UNAUTHORIZED'
        ) {
          logout({ returnTo: window.location.origin });
        }
      });

      return {
        name: 'symphony',
        link: from([
          errorLink,
          symphonyAuthLink.concat(
            new HttpLink({
              uri,
              fetch,
            }),
          ),
        ]),
      };
    },
    [jwt, logout],
  );

  const apolloClient = useMemo(
    () =>
      getApolloClient({
        links: [
          getContentstackHttpLinkConfig(config.contentstack.environment, config.contentstack.deliveryToken),
          getSymphonyHttpLinkConfig(process.env.NODE_ENV === 'development' ? '/ui/graphql' : '/symphony/ui/graphql'),
        ],
        cache: new InMemoryCache({
          typePolicies: {
            ...symphonyCacheTypePolicies,
          },
        }),
      }),
    [getSymphonyHttpLinkConfig],
  );

  const namespace = 'https://fc.sigfig.com';
  useEffect(() => {
    if (user) {
      setJwt(user[`${namespace}:frontEndJwt`]);
    }
  }, [user]);

  useEffect(() => {
    axios.defaults.headers.common.Authorization = jwt ? `Bearer ${jwt}` : '';
  }, [jwt]);

  useEffect(() => {
    const contextPartyId = user?.[`${namespace}:inContextPartyId`];
    if (contextPartyId && loggedInPartyId !== contextPartyId) {
      setLoggedInPartyId(contextPartyId);
      posthog?.identify(contextPartyId);
    }
  }, [loggedInPartyId, posthog, user]);

  const outletComponent = useMemo(() => {
    return authenticationOptional && !isAuthenticated ? (
      <Outlet />
    ) : (
      <ProtectedRoute component={() => <Outlet context={loggedInPartyId} />} />
    );
  }, [authenticationOptional, isAuthenticated, loggedInPartyId]);

  return (
    <ApolloProvider client={apolloClient}>
      {isLoading || (isAuthenticated && !jwt) ? (
        <LoadingPage />
      ) : error ? (
        <>
          <OpsDashboardHeaderV2 contentOptions={contentOptions} showHelpButton showOpsUserIcon />
          <ErrorComponent
            contentOptions={contentOptions}
            isAuthorizationError
            message="Sorry, you are not entitled to view this page."
            suggestion="Please check with your systems administrator or manager to request access."
          />
        </>
      ) : (
        <KeepAlive redirectUrl={routes.opsDashboard(loggedInPartyId)}>{outletComponent}</KeepAlive>
      )}
    </ApolloProvider>
  );
};
