import {
  ManagedProductLegalDocument,
  ManagedProductLegalDocumentSignee,
} from 'src/OperationApp/components/DocusignCompleteWrapper/symphony';
import { routes } from 'src/OperationApp/routes';

import { LegalDocumentStatus } from '@sigfig/digital-wealth-core';

import { getBaseUrl } from '..';

export enum DocusignUserType {
  Assistant = 'Assistant',
  Client = 'Client',
  FA = 'FA',
}
export enum SigneeRole {
  ASSISTANT_FA = 'ASSISTANT_FA',
  CLIENT = 'CLIENT',
  PRIMARY_FA = 'PRIMARY_FA',
}

/** The isClientSignaturePending function returns true if the next pending Signee is a CLIENT.
 * @param {ManagedProductLegalDocumentSignee[]} signeesList List of all signees.
 * @param {string} partyIdFA ID of the Initiating advisor
 * @returns {boolean}
 * */
export const isClientSignaturePending = (
  signeesList: ManagedProductLegalDocumentSignee[],
  partyIdFA: string,
): boolean => {
  const pendingSignee = getNextPendingSignee(signeesList);
  if (pendingSignee) {
    const pendingSigneeRole = getSigneeRole(pendingSignee, partyIdFA);
    return pendingSigneeRole === SigneeRole.CLIENT;
  }
  return false;
};

/** A helper function to compare status of ManagedProductLegalDocumentSignee or ManagedProductLegalDocument to a LegalDocumentStatus.
 * @param status LegalDocumentStatus: Value to be compared with
 * */
export const checkSigningStatusFunction = (status: LegalDocumentStatus) => {
  return (el: ManagedProductLegalDocument | ManagedProductLegalDocumentSignee) => el.status === status;
};

/** The function getSigneeRole RETURNS the userType of the signee.
 @param {ManagedProductLegalDocumentSignee | null | undefined} signee Signee Object.
 @param {string} partyIdFA ID of the primary FA.
 * @return {SigneeRole} SigneeRole of the passed signee
 * */
export const getSigneeRole = (
  signee: ManagedProductLegalDocumentSignee | null | undefined,
  partyIdFA: string,
  assistantFaPartyId?: string | null,
): SigneeRole => {
  return signee?.partyId === partyIdFA
    ? SigneeRole.PRIMARY_FA
    : assistantFaPartyId && signee?.partyId === assistantFaPartyId
    ? SigneeRole.ASSISTANT_FA
    : SigneeRole.CLIENT;
};

/** The function getDocusignUserType RETURNS the userType of the signee.
 * @param {ManagedProductLegalDocumentSignee | null | undefined} signee Signee Object.
 * @param {string} partyIdFA ID of the primary FA.
 * @return {DocusignUserType} DocusignUserType of the passed signee
 * */
export const getDocusignUserType = (
  signee: ManagedProductLegalDocumentSignee | null | undefined,
  partyIdFA: string,
): DocusignUserType => {
  return signee?.partyId === partyIdFA ? DocusignUserType.FA : DocusignUserType.Client;
};

/** The function getNextPendingSignee RETURNS the next pending signee from the given signeesList. The signeesList is sorted based on the recipientId.
 * The ordered List is iterated to check if there exists a signee with status PENDING and returns it.
 * In-case a signee with status DECLINED is found null is returned.
 * @param {ManagedProductLegalDocumentSignee[] | null | undefined} signeeList List of All Signees.
 * @return {ManagedProductLegalDocumentSignee | null}
 * */
// @TODO: Move to core DA2-6043
export const getNextPendingSignee = (
  signeeList: ManagedProductLegalDocumentSignee[] | null | undefined,
): ManagedProductLegalDocumentSignee | null => {
  if (signeeList) {
    const orderedSigneeList = [...signeeList].sort((a, b) => (a.recipientId ?? 0) - (b.recipientId ?? 0));
    for (const signee of orderedSigneeList) {
      if (signee.status === LegalDocumentStatus.PENDING) {
        return signee;
      } else if (signee.status === LegalDocumentStatus.DECLINED) {
        return null;
      }
    }
  }
  return null;
};

export const getDocusignBaseUrl = (): string => `${getBaseUrl()}/am/master`;

export const getDocusignReturnToUrl = (
  partyId: string,
  managedProductId?: string,
  bankAccountAssociationId?: string,
) => {
  return `${getBaseUrl()}${routes.docusignComplete(partyId, managedProductId ?? '', bankAccountAssociationId ?? '')}`;
};
