import { QueryHookOptions, QueryResult, useContentstackQuery } from '@sigfig/digital-wealth-core';

import { GetHeaderContentO, GetHeaderContentOVariables } from './__generated__/GetHeaderContentO';
import * as getHeaderContentQuery from './GetHeaderContent.gql';

export const useGetHeaderContent = (
  options?: QueryHookOptions<GetHeaderContentO, GetHeaderContentOVariables>,
): QueryResult<GetHeaderContentO, GetHeaderContentOVariables> => {
  return useContentstackQuery(getHeaderContentQuery.GetHeaderContentO, options);
};
