import './index.css';

import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';

import { LiveAnnouncer, PostHogProvider, sfPosthog } from '@sigfig/digital-wealth-core';

import Auth0ProviderWithRedirectCallback from '../components/Auth0ProviderWithRedirectCallback';
import * as serviceWorker from '../serviceWorker';

import App from './App';
import config from './config';

ReactDOM.render(
  <BrowserRouter>
    <Auth0ProviderWithRedirectCallback
      clientId={config.auth0.clientId}
      domain={config.auth0.domain}
      redirectUri={window.location.origin}
    >
      <PostHogProvider apiKey={config.posthog.token} options={sfPosthog.DEFAULT_CONFIG}>
        <LiveAnnouncer>
          <App />
        </LiveAnnouncer>
      </PostHogProvider>
    </Auth0ProviderWithRedirectCallback>
  </BrowserRouter>,

  document.getElementById('root'),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
