import {
  MutationHookOptions,
  MutationTuple,
  QueryHookOptions,
  QueryResult,
  useSymphonyMutation,
  useSymphonyQuery,
} from '@sigfig/digital-wealth-core';

import { CreateSigningDocumentO, CreateSigningDocumentOVariables } from './__generated__/CreateSigningDocumentO';
import {
  GetDocusignStatusO,
  GetDocusignStatusO_managedProduct_legalDocuments,
  GetDocusignStatusO_managedProduct_legalDocuments_signees,
  GetDocusignStatusOVariables,
} from './__generated__/GetDocusignStatusO';
import * as createSigningDocumentMutation from './CreateSigningDocument.gql';
import * as getGetDocusignStatusQuery from './GetDocusignStatus.gql';

export type ManagedProductLegalDocument = GetDocusignStatusO_managedProduct_legalDocuments;
export type ManagedProductLegalDocumentSignee = GetDocusignStatusO_managedProduct_legalDocuments_signees;

export const useGetDocusignStatus = (
  options?: QueryHookOptions<GetDocusignStatusO, GetDocusignStatusOVariables>,
): QueryResult<GetDocusignStatusO, GetDocusignStatusOVariables> => {
  return useSymphonyQuery(getGetDocusignStatusQuery.GetDocusignStatusO, options);
};

export const useCreateSigningDocument = (
  options?: MutationHookOptions<CreateSigningDocumentO, CreateSigningDocumentOVariables>,
): MutationTuple<CreateSigningDocumentO, CreateSigningDocumentOVariables> => {
  return useSymphonyMutation(createSigningDocumentMutation.CreateSigningDocumentO, options);
};
