export const getBaseUrl = (location: Location = window.location): string => location.origin;
export const getSymphonyPath = (): string => (process.env.NODE_ENV === 'development' ? '' : '/symphony');
export const isInIframe = (): boolean => window.self !== window.top;

import {
  AccountProfileUpdateState,
  AssociatedEntityType,
  LegalDocumentStatus,
  OnboardingStates,
  QuestionnaireProps,
  QuestionnaireType,
  RetakeRTQStates,
} from '@sigfig/digital-wealth-core';

import { AccountActionRoutes } from '../App';

export const questionnaireProps: Partial<QuestionnaireProps> = {
  combineSoftBlocks: true,
  isCopilot: true,
  questionnaireType: QuestionnaireType.ONBOARDING,
  riskEditDisabled: true,
  showPortfolioBands: false,
};

export const onboardingStatesOrder: OnboardingStates[] = [
  OnboardingStates.MADLIBS,
  OnboardingStates.RTQ,
  OnboardingStates.RTQ_RESULT,
  OnboardingStates.PORTFOLIO_SELECTION,
  OnboardingStates.PLAN,
  OnboardingStates.FUNDING,
  OnboardingStates.PAPERWORK,
  OnboardingStates.PLAYBACK,
];

export const retakeRtqStatesOrder: RetakeRTQStates[] = [
  RetakeRTQStates.RTQ,
  RetakeRTQStates.RTQ_RESULT,
  RetakeRTQStates.PORTFOLIO_SELECTION,
  RetakeRTQStates.COMPARISON,
  RetakeRTQStates.CLIENT_DOCUSIGN,
  RetakeRTQStates.FA_DOCUSIGN,
];

export const getRceDocusignProps = (url: string) =>
  url.includes('journaling-docusign')
    ? AssociatedEntityType.ASSET_DEPOSIT
    : AssociatedEntityType.BANK_ACCOUNT_ASSOCIATION;

export const getRedirectSigningDocumentURL = ({
  associatedEntities,
  managedProductId,
  partyId,
  source,
  state,
}: {
  associatedEntities?: { entityId: string; entityType: AssociatedEntityType };
  managedProductId: string;
  partyId: string;
  source: AccountActionRoutes;
  state?: string;
}) => {
  switch (associatedEntities?.entityType) {
    case AssociatedEntityType.BANK_ACCOUNT_ASSOCIATION:
      return `/bank-account-docusign/${partyId}/${managedProductId}/${associatedEntities.entityId}?source=${source}${
        state ? `&state=${state}` : ''
      }`;
    default:
      return `/rce/${partyId}/questionnaire/${managedProductId}?state=${RetakeRTQStates.FA_DOCUSIGN}`;
  }
};

const LegalDocumentViewingComplete = 'VIEWING_COMPLETE'; // This state exists in the case where user can complete docusign but clicks Close instead.

export const getAccountProfileUpdateStatesOrder = (status: any, entity: any): AccountProfileUpdateState => {
  // Here we are handling only the client unauthenticated View, Since account profile do not have FA signature, and no docusign signing while logged in
  if (status === LegalDocumentStatus.SUCCEEDED) {
    return entity
      ? AccountProfileUpdateState.CLIENT_WITH_BENEFICIARIES_COMPLETED_UNAUTHENTICATED_VIEW
      : AccountProfileUpdateState.CLIENT_COMPLETED_UNAUTHENTICATED_VIEW;
  } else if (
    status === 'NONE' ||
    status === LegalDocumentStatus.PENDING ||
    status === LegalDocumentStatus.PENDING_PRIMARY ||
    status === LegalDocumentStatus.PENDING_SECONDARY ||
    status === LegalDocumentViewingComplete
  ) {
    return AccountProfileUpdateState.FINISH_LATER_UNAUTHENTICATED_VIEW;
  } else {
    return AccountProfileUpdateState.CLIENT_DECLINED_UNAUTHENTICATED_VIEW;
  }
};
