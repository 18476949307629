import React, { lazy, Suspense } from 'react';
import { Route, Routes } from 'react-router-dom';

import { Box, CircularProgress, CoreConfigProvider, createTheme, ThemeProvider } from '@sigfig/digital-wealth-core';

import { AuthedApp } from './components/AuthedApp';
import DocusignCompletedWrapper from './components/DocusignCompleteWrapper';
import { defaultPartnerConfigs } from './config';
import { PageRoute } from './routes';
import { defaultTheme } from './theme';

export enum AccountActionRoutes {
  ACCOUNT_DETAILS = 'AccountDetails',
  ACCOUNT_SUMMARY = 'AccountSummary',
}

const AccountProfileWrapper = lazy(() => import('./components/AccountProfile'));
const AccountProfileDocusignCompleted = lazy(() => import('./components/AccountProfileDocusignCompleted'));
const DigitalWealth = lazy(() => import('./components/DigitalWealth'));
const DocusignWrapper = lazy(() => import('./components/DocusignWrapper'));
const OpsDashboard = lazy(() => import('./components/OpsDashboardWrapper'));
const NewOpsDashboard = lazy(() => import('./components/NewOpsDashboardWrapper'));
const RouteEntry = lazy(() => import('./components/RouteEntry'));
const Search = lazy(() => import('./components/Search'));

declare let window: Window;

function App() {
  return (
    <ThemeProvider theme={createTheme(defaultTheme)}>
      <CoreConfigProvider value={defaultPartnerConfigs}>
        <Suspense
          fallback={
            <Box sx={{ py: 2, display: 'flex', justifyContent: 'center' }}>
              <CircularProgress disableShrink />
            </Box>
          }
        >
          <Routes>
            <Route element={<AuthedApp />}>
              <Route element={<AccountProfileWrapper />} path={PageRoute.AccountProfile} />
              <Route element={<DigitalWealth />} path={PageRoute.DigitalWealth} />
              <Route element={<DocusignWrapper />} path={PageRoute.Docusign} />
              <Route element={<Search />} path={PageRoute.OpsDashboardSearch} />
              <Route element={<NewOpsDashboard />} path={PageRoute.OpsDashboard} />
              <Route element={<OpsDashboard />} path={PageRoute.OldOpsDashboard} />
              <Route element={<RouteEntry />} path="/" />
              <Route element={<DocusignCompletedWrapper />} path={PageRoute.RceDocusignCompletedAuth} />
              {/* TODO: Add page not found component */}
            </Route>
            <Route element={<AuthedApp authenticationOptional />}>
              <Route element={<AccountProfileDocusignCompleted />} path={PageRoute.AccountProfileDocusignComplete} />
              <Route element={<DocusignCompletedWrapper />} path={PageRoute.DocusignComplete} />
            </Route>
          </Routes>
        </Suspense>
      </CoreConfigProvider>
    </ThemeProvider>
  );
}
export default App;
