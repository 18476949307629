import React, { FC } from 'react';
import { useAwaitingClientSign } from 'src/OperationApp/hooks/useAwaitingClientSign';

import {
  AlertAndLoading,
  Box,
  Button,
  ContentOptions,
  Grid,
  OnboardingPageHeader,
  RteContent,
  Typography,
} from '@sigfig/digital-wealth-core';

export interface Props {
  clientEmail: string;
  clientSignButtonOnClick?: () => void;
  contentOptions: ContentOptions;
  dataQa?: string;
  isForBankAccountAssociation?: boolean;
}

export const AwaitingClientSign: FC<Props> = ({
  clientSignButtonOnClick,
  contentOptions,
  dataQa = 'awaiting-client-sign',
  clientEmail,
  isForBankAccountAssociation,
}) => {
  const {
    data: getAwaitingClientSignContentData,
    loading: getAwaitingClientSignContentLoading,
    error: getAwaitingClientSignContentError,
  } = useAwaitingClientSign(contentOptions);

  return getAwaitingClientSignContentLoading || getAwaitingClientSignContentError ? (
    <Box sx={{ display: 'flex', justifyContent: 'center', mt: 0 }}>
      <AlertAndLoading
        ariaLabel="awaiting-client-sign"
        contentOptions={contentOptions}
        error={getAwaitingClientSignContentError}
        loading={getAwaitingClientSignContentLoading}
      />
    </Box>
  ) : (
    <Grid alignItems="center" container data-qa={dataQa} direction="column" justifyContent="center">
      {getAwaitingClientSignContentData && (
        <>
          <OnboardingPageHeader
            dataQa={`${dataQa}-header`}
            headerIcon={getAwaitingClientSignContentData.imageUrl}
            title={getAwaitingClientSignContentData.heading}
          />
          <RteContent
            config={{ clientEmail }}
            data={getAwaitingClientSignContentData.paragraphOne}
            sx={{ textAlign: 'center' }}
          />
          <Typography data-qa={`${dataQa}-sub-header-1`} sx={{ py: 1, textAlign: 'center' }}>
            {getAwaitingClientSignContentData.paragraphTwo}
          </Typography>
          <Grid item xs={3}>
            <Button onClick={clientSignButtonOnClick} sx={{ mt: 3, color: 'text.primary' }} variant="outlined">
              <Typography component="span">
                {isForBankAccountAssociation
                  ? getAwaitingClientSignContentData.rcePrimaryButtonCta
                  : getAwaitingClientSignContentData.primaryButtonCta}
              </Typography>
            </Button>
          </Grid>
        </>
      )}
    </Grid>
  );
};
