import { QueryHookOptions, QueryResult, useContentstackQuery } from '@sigfig/digital-wealth-core';

import {
  GetAwaitingClientSignContentO,
  GetAwaitingClientSignContentOVariables,
} from './__generated__/GetAwaitingClientSignContentO';
import { GetDocusignContentO, GetDocusignContentOVariables } from './__generated__/GetDocusignContentO';
import * as awaitingClientSignQuery from './GetAwaitingClientSignContent.gql';
import * as docusignQuery from './GetDocusignContent.gql';

export const useGetAwaitingClientSignContent = (
  options?: QueryHookOptions<GetAwaitingClientSignContentO, GetAwaitingClientSignContentOVariables>,
): QueryResult<GetAwaitingClientSignContentO, GetAwaitingClientSignContentOVariables> => {
  return useContentstackQuery(awaitingClientSignQuery.GetAwaitingClientSignContentO, options);
};

export const useGetDocusignContent = (
  options?: QueryHookOptions<GetDocusignContentO, GetDocusignContentOVariables>,
): QueryResult<GetDocusignContentO, GetDocusignContentOVariables> => {
  return useContentstackQuery(docusignQuery.GetDocusignContentO, options);
};
