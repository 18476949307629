import { AppState, Auth0Provider, Auth0ProviderOptions } from '@auth0/auth0-react';
import React from 'react';
import { useNavigate } from 'react-router-dom';

const Auth0ProviderWithRedirectCallback: React.FC<Auth0ProviderOptions> = ({ children, ...props }) => {
  const navigate = useNavigate();
  const onRedirectCallback = (appState?: AppState) => {
    navigate((appState && appState.returnTo) || window.location.pathname);
  };
  return (
    <Auth0Provider onRedirectCallback={onRedirectCallback} {...props}>
      {children}
    </Auth0Provider>
  );
};

export default Auth0ProviderWithRedirectCallback;
